body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  background-color: #f8f8f8;
  color: #101010;
  overflow-x: hidden;
}
a {
  color: #101010;
  text-decoration: none;
}
a:hover {
  color: #101010;
  text-decoration: underline;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #101010;
}
.lookup_view {
  width: 100%;
  height: min-content;
}
.lookup_view_head {
  width: 100%;
  height: 50vh;
  background: #fff;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: visible;
  position: relative;
  padding: 0 30px;
  transition: height .5s;
  transition-delay: .25s;
}
.lookup_view_body {
  padding: 130px 0;
}
.lookup_bar {
  margin: 0 auto;
  transform: translateY(50%);
  z-index: 1;  
  grid-gap: 1px;
  width: 100%;
  min-height: 60px;
  display: grid;
  grid-template-rows: 1fr;
  overflow: visible;
  border: var(--border);
  transition: box-shadow .25s;
}
.lookup_bar .lookup_small_inp {
  min-width: 180px !important;
  width: 180px !important;
  flex: 0 1;
}
input {
  box-shadow: none !important;
}
.lookup_view h2 {
  font-size: 28px;  
}
.lookup_view_body .accordion-item,
.dash_content .accordion-item {
  margin-bottom: 15px;
  border: 1px solid #ebebeb;
}
.lookup_view_body .accordion-button,
.dash_content .accordion-button {
  box-shadow: none !important;
}
.main_navbar li a {
  cursor: pointer;
  text-decoration: none !important;
  text-transform: uppercase;
  font-size: 14px;
}
.navbar_right a.nav-link.active {
  color: #2585df;
}
.header_network_address {
  position: absolute;
  right: 80px;
  top: 64px;
  z-index: 1;
  font-size: 10px;
}
.header_network_address span {
  text-transform: uppercase;
  margin-right: 10px;
  color: #999;
}
.header_network_address div {
  margin-bottom: 3px;
}
.lookup_view_results .lookup_view_head {
  height: 180px;
}
.token_details_content {
  grid-template-rows: 1fr 1fr 1fr;
  display: grid;
  border-top: 1px solid #ddd;
  margin-bottom: 50px;
}
.token_details_content > div {
  min-height: 60px;
  display: grid;
  grid-template-columns: 150px 1fr;
  padding: 14px 10px;
  align-items: center;
  border-bottom: 1px solid #ddd;
}
.token_details_content > div span {
  font-size: 14px;
}
.token_details_content > div span:first-child {
  font-size: 12px;
  text-transform: uppercase;
}
.token_royalties_content {
  margin-bottom: 50px;
}
.token_royalties_content thead th {
  font-size: 12px;
  text-transform: uppercase;
  padding: 10px;
  text-align: right;
}
.token_royalties_content tbody td {
  font-size: 14px;
  padding: 15px 10px;
  text-align: right;
}
.token_royalties_content thead th:first-child,
.token_royalties_content tbody td:first-child {
  text-align: left;
}
i.bi.bi-search,
i.bi.bi-arrow-right {
  font-size: 24px;
}
.configure_view {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.configure_view_inner {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 0;
  transition: transform .75s ease-in-out;
  padding: 120px 0;
  background-color: #fff;
}
.configure_view_inner button.primary_btn {
  width: 100%;
  height: 60px;
  border: 1px solid #ddd;
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 15px;
  transition: background .25s;
  background: transparent;
}
.configure_view_inner button.primary_btn:hover {
  background: rgba(0,0,0,.05);
}
.configure_view_inner .lookup_bar {
  transform: unset;
}
.configure_view h2 {
  font-size: 24px;
}
.dash_content {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 0;
  transition: transform .75s ease-in-out;
  padding: 120px 0;
  background-color: #fff;
}
a.navbar-brand {
  text-decoration: none;
  font-size: 18px;
}